import type { ISortingConfigItem, ISortOrder } from "@magnit/unit-catalog/src/unit-catalog.types";
import {
  CatalogBFFSortV2Order,
  CatalogBFFSortV2Type,
  type ICatalogBFFSortV2,
} from "~/typings/api/goods";

export const NEW_PRODUCTS_LIMIT = 12;
export const PRODUCTS_LIMIT = 36;
export const PRODUCTS_BANNER_LIMIT_OFFSET = 3;
export const BANNER_POSITION_INDEX = 4;
export const DOUBLE_BANNER_POSITION_INDEX = 9;
export const SORT_DISCOUNT = "discount";
export const SORT_DISCOUNT_PERCENTAGE = "discountPercentage";

export const DEFAULT_SORT_VALUE_PROMO: string = "priority";
export const DEFAULT_SORT_ORDER: ISortOrder = "desc";
export const DEFAULT_SORT_BFF_GOOD: ICatalogBFFSortV2 = {
  order: CatalogBFFSortV2Order.desc,
  type: CatalogBFFSortV2Type.popularity,
};

export const promoSortingConfigDesktop: ISortingConfigItem[] = [
  {
    title: "По популярности",
    value: DEFAULT_SORT_VALUE_PROMO,
  },
  {
    title: "По цене",
    value: "price",
    reversible: true,
  },
  {
    title: "По размеру скидки",
    value: "discountPercentage",
  },
  {
    title: "По дате акции",
    value: "endDate",
    reversible: true,
  },
];
export const promoSortingConfigMobile: ISortingConfigItem[] = [
  {
    title: "По популярности",
    value: DEFAULT_SORT_VALUE_PROMO,
    order: "desc",
  },
  {
    title: "Сначала дороже",
    value: "price",
    order: "desc",
  },
  {
    title: "Сначала дешевле",
    value: "price",
    order: "asc",
  },
  {
    title: "По размеру скидки",
    value: "discountPercentage",
    order: "desc",
  },
  {
    title: "По дате начала акции",
    value: "endDate",
    order: "desc",
  },
  {
    title: "По дате окончания акции",
    value: "endDate",
    order: "asc",
  },
];
export const goodsSortingConfigDesktop: ISortingConfigItem[] = [
  {
    title: "По популярности",
    value: CatalogBFFSortV2Type.popularity,
  },
  {
    title: "По цене",
    value: CatalogBFFSortV2Type.price,
    reversible: true,
  },
  {
    title: "По размеру скидки",
    value: CatalogBFFSortV2Type.discount,
  },
];
export const goodsSortingConfigMobile: ISortingConfigItem[] = [
  {
    title: "По популярности",
    value: CatalogBFFSortV2Type.popularity,
    order: CatalogBFFSortV2Order.desc,
  },
  {
    title: "Сначала дороже",
    value: CatalogBFFSortV2Type.price,
    order: CatalogBFFSortV2Order.desc,
  },
  {
    title: "Сначала дешевле",
    value: CatalogBFFSortV2Type.price,
    order: CatalogBFFSortV2Order.asc,
  },
  {
    title: "По размеру скидки",
    value: CatalogBFFSortV2Type.discount,
    order: CatalogBFFSortV2Order.desc,
  },
];
